.mentor1{
    width: 380px;
    height: 95px;
    background: url("../../Assets/Mentor1.svg");
    background-size: 380px 95px;
    background-repeat: no-repeat;
}
.mentor2{
    width: 330px;
    height: 100px;
    background: url("../../Assets/Mentor2.svg");
    background-size: 330px 100px;
    background-repeat: no-repeat;
}
.mentor3{
    width: 380px;
    height: 95px;
    background: url("../../Assets/Mentor3.svg");
    background-size: 380px 95px;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 450px) {
    .mentor1{
        width: 280px;
        height: 85px;
        background: url("../../Assets/Mentor1.svg");
        background-size: 280px 85px;
        background-repeat: no-repeat;
    }
    .mentor2{
        wwidth: 280px;
        height: 85px;
        background: url("../../Assets/Mentor2.svg");
        background-size: 280px 85px;
        background-repeat: no-repeat;
    }
    .mentor3{
        width: 280px;
        height: 85px;
        background: url("../../Assets/Mentor3.svg");
        background-size: 280px 85px;
        background-repeat: no-repeat;
    }
}