.value-prop-div{
    width: 100%;
    min-height: 90vh;
    height: fit-content;
    background: #F9FFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.value-prop-background-div{
    position: absolute;
    width: 100%;
    height: inherit;
    z-index: 0;
    display: flex;
    flex-direction: column;
}


.value-prop-title-div{
    /* margin-left: 10vw;
    margin-right: 10vw; */
    width: 80%;
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;

    /* text/headings */

    color: #0E3256;
    margin-bottom: 40px;
    margin-top: 40px;
}

.value-prop-content-row-div{
    margin-top: 10px;
    width: calc(80% + 10px);
    /* margin-left: 10vw;
    margin-right: 10vw; */
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    z-index: 5;
    margin-bottom: 40px;
}

.value-prop-content-item{
    width: 30%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    min-width: 280px;
    margin-bottom: 30px;
}

.value-prop-content-item-title{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;

    /* text/secondary heading */

    color: #0A56E9;
    margin-top: 40px;
    margin-bottom: 15px;
}

.value-prop-content-item-content{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */


    /* text/body */

    color: #0A0A0A;
}

.value-prop-content-item-image{
    width : 80px;
    height : 80px;
    background : #8DC740;
    border-radius : 6px;
    display : flex;
    align-items : center;
    justify-content : center;
}

@media only screen and (max-width: 1000px) {
    .value-prop-title-div{
        font-size: 28px;
        line-height: 40px;
    }
    .value-prop-content-item-image{
        width : 60px;
        height : 60px;
    }
    .value-prop-content-item-title{
        font-size: 22px;
        line-height: 28px;
        margin-top: 30px;

    }
}

@media only screen and (max-width: 700px) {
    .value-prop-title-div{
        font-size: 24px;
        line-height: 36px;
    }
    .value-prop-content-item-title{
        font-size: 20px;
        line-height: 26px;
        margin-top: 30px;

    }
}