.projects-div{
    width: 100%;
    min-height: 70vh;
    height: fit-content;
    background: #F9FFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.projects-title-div{
    /* margin-left: 10vw;
    margin-right: 10vw; */
    width: 80%;
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;

    /* text/headings */

    color: #0E3256;
    margin-bottom: 40px;
    margin-top: 40px;
}

.projects-description-div{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    color: #0A0A0A;
    width: 80%;

}

.projects-carousel-div{
    width: 100%;
    height: fit-content;
}

.projects-carousel-content-div{
    width: 100%;
    min-height: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-carousel-cards-div{
    flex: 1;
    width: 80vw;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 50px;

}
.projects-carousel-card-item{
    width: 250px;
    height: 320px;
    background: #FFFFFF;
    /* shadow basic */
    border-radius: 12px;
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
    margin: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-carousel-card-item-description{
    width: 200px;
    text-align: left;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: black;
    margin-bottom: 15px;
}

.projects-carousel-card-item-tags-div{
    width: 208px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
}

.projects-carousel-card-item-tag-item{
    color: #007D85;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    border: 1px solid #B3D8DA;
    border-radius: 2px;
    background: #E0EFF0;

    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;

    margin: 4px;
}

.ant-carousel .slick-slide {
    text-align: center;
    height: fit-content;
    overflow: hidden;
    margin-top: 50px;
  }

  .ant-carousel .slick-dots-bottom{
      z-index: 1;
  }

.ant-carousel .slick-dots li{
    background: #0a56e9;
    height: 8px;
    width: 43px;
    border-radius: 1px;
    margin-right: 6px;
    margin-left: 6px;
}

.ant-carousel .slick-dots li button{
    height: 8px;
    width: 43px;
    border-radius: 1px;
    opacity: 0.7;
}


.ant-carousel .slick-dots li.slick-active {
    background: #0a56e9;
    height: 8px;
    width: 64px;
    border-radius: 1px;
    margin-right: 6px;
    margin-left: 6px;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #0a56e9;
    height: 8px;
    width: 64px;
    border-radius: 1px;
}


@media only screen and (max-width: 1000px) {
    .projects-title-div{
        font-size: 28px;
        line-height: 40px;
    }
    .projects-description-div{
        font-size: 16px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 700px) {
    .projects-title-div{
        font-size: 24px;
        line-height: 36px;
    }
    .projects-description-div{
        font-size: 14px;
        line-height: 22px;
    }
}