.sponsor-div{
    min-height: 50vh;
    height: fit-content;
    width: 100%;
    background: #F9FFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sponsor-content-div{
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    height: 80%;
    min-height: 280px;
    flex-wrap: wrap-reverse;
}

.sponsor-content-half{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 380px;
}

.sponsor-title-div{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;

    /* text/headings */

    color: #0E3256;
    margin-bottom: 25px;
}

.sponsor-content-text{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */


    /* text/body */

    color: #0A0A0A;
    margin-bottom :25px;
    margin-right: 20px;
}

.sponsor-logos-div{
    width: 480px;
    height: 280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.sponsor1{
    width: 380px;
    height: 95px;
    background: url("../../Assets/Sponsor1.svg");
    background-size: 380px 95px;
    background-repeat: no-repeat;
}
.sponsor2{
    width: 330px;
    height: 100px;
    background: url("../../Assets/Sponsor2.svg");
    background-size: 330px 100px;
    background-repeat: no-repeat;
}
.sponsor3{
    width: 380px;
    height: 95px;
    background: url("../../Assets/Sponsor3.svg");
    background-size: 380px 95px;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 1000px) {
    .sponsor-title-div{
        font-size: 28px;
        line-height: 40px;
    }
    .sponsor-content-text{
        font-size: 16px;
        line-height: 24px;
    }
    .sponsor-title-div{
        margin-top: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .sponsor-title-div{
        font-size: 24px;
        line-height: 36px;
    }
    .sponsor-content-text{
        font-size: 14px;
        line-height: 22px;
    }
    .sponsor-title-div{
        margin-top: 40px;
    }
    
}

@media only screen and (max-width: 450px) {
    .sponsor1{
        width: 300px;
        height: 87.5px;
        background: url("../../Assets/Sponsor1.svg");
        background-size: 300px 80px;
        background-repeat: no-repeat;
    }
    .sponsor2{
        width: 280px;
        height: 85px;
        background: url("../../Assets/Sponsor2.svg");
        background-size: 280px 85px;
        background-repeat: no-repeat;
    }
    .sponsor3{
        width: 280px;
        height: 85px;
        background: url("../../Assets/Sponsor3.svg");
        background-size: 280px 85px;
        background-repeat: no-repeat;
    }
    .sponsor-content-half{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 280px;
    }
}