.about-div{
    min-height: 450px;
    height: fit-content;
}

.about-background-div{
    position: absolute;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    
}

.about-content-div{
    min-height: 300px;
    height: fit-content;
    background: linear-gradient(180deg, #F1FFFF 0%, #F9FFFF 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    padding-top: 20px;
}

.about-timeline-div{
    min-height: 150px;
    background: #F9FFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.about-content-title{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;

    /* text/headings */

    color: #0E3256;
    z-index: 5;
}

.about-content-subheading{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    text-align: center;

    /* text/body */

    color: #0A0A0A;
    width: 800px;
    margin-top: 15px;
    z-index: 5;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #0a56e9;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #0a56e9;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #0a56e9;
}

@media only screen and (max-width: 1000px) {
    .about-content-title{
        font-size: 28px;
        line-height: 40px;
    }
    
    .about-content-subheading{
        font-size: 16px;
        line-height: 24px;
        width: 600px;

    }
    .about-timeline-div{
        padding-bottom: 40px;
    }
}


@media only screen and (max-width: 700px) {
    .about-content-title{
        font-size: 24px;
        line-height: 36px;
    }
    
    .about-content-subheading{
        font-size: 14px;
        line-height: 22px;
        width: 300px;
        margin-bottom: 20px;
    }

    .about-content-div{
        padding-bottom: 40px; 
    }
    .about-timeline-div{
        padding-bottom: 40px;
    }
}