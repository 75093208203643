.navbar-div{
    width: 100%;
    height: 80px;
    background: #F5FFE8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    transition: 0.25s ease-in background-color;
    z-index: 10;
}

.navbar-logo-div{
    display: flex;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
}

.navbar-links-div{
    display: flex;
    justify-content: space-between;
    flex: 3;
}
.navbar-filler-div{
    flex: 3.5;
}

.navbar-icons-div{
    /* flex: 1.3; */
    width: 150px;
    min-width: 150px;
    display: flex;
    justify-content: space-around;
}

.navbar-action-div{
    display: flex;
    justify-content: center;
    margin-right: 50px;
    margin-left: 40px;
}

.navbar-link-item{
    margin: 10px;
    color: #0E3256;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    transition: 0.15s ease-in all;

}

.navbar-link-item:hover{
    cursor: pointer;
    color: #007D85;
}

.scrolled-navbar{
    background: white;
    box-shadow: 0px 9px 28px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.12);
}

.navbar-logo:hover{
    cursor:pointer;
}

.navbar-logo{
    width: 30px;
    height: 48px;
    background: url("../../Assets/NoWordsLogo.svg")
}

.hamburger{
    margin-right: 20px;
}
.line{
  width: 20px;
  height: 2px;
  background-color: #0E3256;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
    cursor: pointer;
}

  
#hamburger-1.is-active .line:nth-child(2){
    opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
-webkit-transform: translateY(7px) rotate(45deg);
-ms-transform: translateY(7px) rotate(45deg);
-o-transform: translateY(7px) rotate(45deg);
transform: translateY(7px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
-webkit-transform: translateY(-7px) rotate(-45deg);
-ms-transform: translateY(-7px) rotate(-45deg);
-o-transform: translateY(-7px) rotate(-45deg);
transform: translateY(-7px) rotate(-45deg);
}

.navbar-mobile-div{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #F9FFFF;
    top: 0px;
    /* margin-top: calc(100vh - 70px); */
    /* margin-top: calc(50vh - 35px); */
    /* margin-top: 50vh; */

    transition: 0.25s ease-in-out all;
    flex-direction: column;
    justify-content: center;
    display: none;
}

.is-shown{
    z-index: 0;
    opacity: 100%;
    display: flex;
}

.show-nav{
    opacity: 100%;
    display: flex;
}

.navbar-mobile-links-div{
    margin-left: 70px;
    margin-top: 20px;
    flex-direction: column;
}

.navbar-mobile-link-item{

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* text/headings */

    color: #0E3256;
    margin-bottom: 25px;
}

.navbar-mobile-link-item:hover{
    cursor: pointer;
}
@media only screen and (max-width: 780px) {
    .navbar-div{
        height: 70px;
    }
    .navbar-links-div{
        display: none;
    }
    .navbar-icons-div{
        display: none;
    }
    .navbar-action-div{
        display: none;
    }

    .navbar-logo{
        width: 17px;
        height: 28px;
        background: url("../../Assets/NoWordsLogo.svg");
        background-size: 17px 28px;
    }
    .navbar-logo-div{
        margin-left: 30px;
        margin-right: 30px;
    }
}

@media only screen and (min-width: 781px) {
    .hamburger{
        display: none;
    }
    .navbar-mobile-div{
        display: none;
        opacity: 0%;
    }
}