@import '~antd/dist/antd.less';
// @button-color: #6130ED; 
@button-color-hover: #7E50FE; 

.App{
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #F5FFE8;

}

.ant-btn-primary {
  // background-color: @button-color;
  border-radius: 4px;
  // border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.ant-btn-primary:hover{
  background-color: @button-color-hover;
}

::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}


::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b6b6b6;
  border-radius: 100px;
}


@primary-color: #6130ED;@font-family: Inter;