.hero-div{
    min-height: calc(100vh - 80px);
    height: fit-content;
    margin-top: 80px;
    background: linear-gradient(180deg, #F5FFE8 0%, #F1FFFF 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
}

.hero-logo-div{
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 5;
}

.hero-title-div{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 65px;
    color: #0E3256;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 5;
}

.hero-subtitle-div{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #0A0A0A;

    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    z-index: 5;
}

.hero-button-div{
    width: 100%;
    display: flex;
    justify-content: center;    
    margin-top: 30px;
    z-index: 5;
}

.hero-background-div{
    position: absolute;
    width: 100%;
    height: inherit;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-partners-div{
    min-height: 20vh;
    height: fit-content;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.hero-partners-content-div{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.logo-container-div{
    margin: auto;
    margin-top: 20px;
}

.logo-div{
    margin-left: 17.5px;
    margin-right: 17.5px;
}
/* .hero-partners-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 43px;
    color: #0E3256;
} */

.exec-application{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    color: #007D85;

    margin-right: 10px;
}

.exec-application-container{
    display: flex;
    align-items: center;
}

.background-diamond-1{
    background-image: url("../../Assets/PNGDiamonds/1.png");
    background-position: center center;
    min-width: 50px;
    min-height: 50px;
}

.testtt{
    width: 50px;
    height: 50px;
    background: blue;
    background: url("../../Assets/PNGDiamonds/1.png")
}

.hero-content-div{
    width: 850px;
}

.hero-fellowship-logo{
    width: 275px;
    height: 48px;
    background: url("../../Assets/FellowshipFull.png");
    background-size: 275px 48px;
}

@media only screen and (max-width: 1000px) {
    .hero-title-div{
        font-size: 45px;
        line-height: 55px;
        color: #0E3256;
    }

    .hero-content-div{
        width: 600px;
    }
    
    .hero-subtitle-div{
        font-size: 16px;
        line-height: 24px;
    }

    .hero-partners-div{
        font-size: 22px;
        line-height: 38px;
    }
    .exec-application{
        font-size: 20px;
    }
}

@media only screen and (max-width: 700px) {
    .hero-title-div{
        font-size: 32px;
        line-height: 45px;
    }
    .hero-content-div{
        width: 300px;
    }
    .hero-subtitle-div{
        font-size: 14px;
        line-height: 22px;
    }
    .hero-partners-div{
        font-size: 20px;
        line-height: 36px;
    }
    .exec-application{
        font-size: 16px;
    }
}

@media only screen and (max-width: 780px) {

    .hero-div{
        min-height: calc(100vh - 70px);
        margin-top: 70px;

    }
}

@media only screen and (min-height: 800px) {
    .hero-partners-div{
        min-height: 25vh;
        height: fit-content;
        justify-content: center;
    }
    .hero-partners-content-div{
        margin-bottom: 50px;
    }
}