.faq-div{
    width: 100%;
    height: fit-content;
    background: #F9FFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
}

.faq-title-div{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;

    /* text/headings */

    color: #0E3256;
    margin-top: 40px;
}

.ant-collapse-header{
    background: white;
}

.ant-collapse-content-box{
    background: #F5F8FE;
}


.faq-content-div{
    width: 80%;
    height: 100%;
    margin-top: 40px;
    min-height: fit-content;
    margin-bottom: 40px;
    min-width: 280px;
    max-width: 850px;
}

@media only screen and (max-width: 1000px) {
    .faq-title-div{
        font-size: 28px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .faq-title-div{
        font-size: 24px;
        line-height: 36px;
    }
}