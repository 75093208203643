.footer-div{
    min-height: 330px;
    background: #E7FBFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-height: 210px;

}

.footer-content-div{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 270px;
}

.footer-content-div{
    min-height: fit-content;
}

.footer-bottom-div{
    min-height: 60px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
}

.footer-title-content{
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;

    /* text/headings */

    color: #0E3256;
    margin-top: 40px;
}

.footer-copyright{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* text/body */

    color: #0A0A0A;
    margin-left: 30px;
    flex: 1;
    min-width: 250px;
    margin-right: 30px;
    margin-bottom: 15px;

}

.footer-privacy{
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* text/body */

    color: #0A0A0A;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 15px;

}

.social-icon{
    width: 24px;
    height: 24px;
    transition: 0.15s ease-in-out all;
}


.linkedin{
    background: url("../../Assets/linkedinH.svg")
}
.instagram{
    background: url("../../Assets/instagramH.svg")
}
.email{
    background: url("../../Assets/mailH.svg")
}

.social-icon:hover{
    cursor: pointer;
}

.hover-icon{
    transition: 0.15s ease-in-out all;
}

.hover-icon:hover{
    opacity: 0%;
}

.footer-icons-div{
    /* flex: 1.3; */
    width: 150px;
    min-width: 150px;
    display: flex;
    justify-content: space-around;
}

.footer-logo{
    margin-left: 30px;
    width: 115px;
    height: 40px;
    background: url("../../Assets/words.png");
    background-size: 115px 40px;
    margin-bottom: 15px;
}

.footer-copyright-linebreak{
    display: none;
}

@media only screen and (max-width: 690px) {
    .footer-copyright-linebreak{
        display: block;
    }
}

@media only screen and (max-width: 1000px) {
    .footer-title-content{
        font-size: 28px;
        line-height: 40px;
    }
    
}

@media only screen and (max-width: 1000px) {
    .footer-title-content{
        font-size: 24px;
        line-height: 36px;
    }
    .footer-copyright{
        font-size: 12px;
        margin-right: 30px;
    }
    .footer-privacy{
        margin-right: 30px;
        font-size: 12px;
    
    }
    
}