.privacy-policy-div{
    margin-top: 120px;
    margin-left: 50px;
    margin-right: 50px;

    margin-bottom: 50px;
}

@media only screen and (max-width: 700px) {
    .privacy-policy-div{
        margin-left: 30px;
        margin-right: 30px;
    }

}